<template>
  <div class="action">
    <h2>Batch Move to Stage</h2>
    <b-container v-if="this.$store.state.isFirstActionVisualization">
      <b-row align-v="center">
        <b-col sm="4">
          <label><b>Current Stage:</b></label>
        </b-col>
        <b-col sm="8">
          <label>Abstract Screening/Multiple Stages</label>
        </b-col>
      </b-row>
      <b-row style="background: #bde0a6; padding:5px;" align-v="center">
        <b-col sm="4">
          <label style="color: black;"><b>Push to:</b></label>
        </b-col>
        <b-col sm="8">
          <kendo-dropdownlist
            options-label="Select stage..."
            :data-source="stageDataSource"
            :data-text-field="'StageName'"
            :data-value-field="'StageIdentity'">
          </kendo-dropdownlist>
        </b-col>
      </b-row>
      <b-row align-v="center">
        <b-col style="text-align: center;"><b>Or</b></b-col>
      </b-row>
      <b-row style="background: #db6664; padding:5px;" align-v="center">
        <b-col sm="4">
          <label style="color: black;"><b>Exclusion Reason:</b></label>
        </b-col>
        <b-col sm="8">
          <div>
            <kendo-multiselect
              :data-source="ExclusionReasonDataSource"
              :data-value-field="'ExclusionReasonIdentity'"
              :data-text-field="'ExclusionReasonName'"
              placeholder="Select reason..."
              filter="contains">
            </kendo-multiselect>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div class="second-action-container" v-if="!this.$store.state.isFirstActionVisualization">
      <div class="label-container">
        <label><b>From current Stage:</b> Abstract Screening <b>To:</b></label>
      </div>
      <div class="button-container">
        <button class="button1" @click="toggleButton1" @shortkey="toggleButton1" ref="button1">
            <h3 ref="button1Title"><u>I</u>nclude (Ctrl+I)</h3>
            <h4 ref="button1Label">Push to:</h4>
            <div v-on:click.stop>
              <kendo-dropdownlist
                class="button1-input"
                :ref = "'button1Input'"
                :data-source="stageDataSource"
                :data-text-field="'StageName'"
                :data-value-field="'StageIdentity'">
              </kendo-dropdownlist>
            </div>
        </button>
        <button class="button2" @click="toggleButton2" @shortkey="toggleButton2" ref="button2">
            <h3 ref="button2Title"><u>E</u>xclude (Ctrl+E)</h3>
            <h4 ref="button2Label">Reason(s):</h4>
            <div v-on:click.stop>
              <kendo-multiselect
                class="button2-input"
                :ref = "'button2Input'"
                :data-source="ExclusionReasonDataSource"
                :data-value-field="'ExclusionReasonIdentity'"
                :data-text-field="'ExclusionReasonName'"
                placeholder="Select reason..."
                filter="contains">
                </kendo-multiselect>
            </div>
        </button>
        <button class="button3" @click="toggleButton3" ref="button3">
            <h3 ref="button3Title">Rollback to previous Stage: Stage xxx</h3>
        </button>
      </div>
    </div>
    <div class="action-buttons">
      <div class="action-buttons-nav">Alert: you are going to accept all 7 selected publications.  Click the Accept button if you confirm or cancel to ignore action</div>
      <div class="action-buttons-nav">
        <b-button type="submit" variant="primary" size="sm">Accept</b-button>
      </div>
      <div class="action-buttons-other">
        <b-button type="submit" variant="primary" size="sm">Cancel</b-button>
      </div>
    </div>
    <h2>Annotations:close?</h2>
  </div>
</template>

<script>
import StageDataSource from '@/assets/data/Stage.json'
import ExclusionReasonDataSource from '@/assets/data/ExclusionReason.json'

export default {
  name: 'abstract',
  props: ['type'],
  methods: {
    itemTemplate: function () {
      return `<div class="product-view k-widget action-annotations-item">
            <dl>
              <dd><b>#:Date# - #:Author#</b> #:MessageTrunc#</dt>
            </dl>
          </div>`
    },
    toggleButton1: function () {
      this.activateAccept1 = !this.activateAccept1
      if (this.activateAccept1) {
        this.$refs.button1.className = 'button1-include'
        this.$refs.button2.className = 'button2'
        this.$refs.button3.className = 'button3'
        this.button1InputWidget.enable(true)
        this.button2InputWidget.enable(false)
        this.button2InputWidget.value('')
        this.activateAccept2 = false
        this.activateAccept3 = false
      } else {
        this.$refs.button1.className = 'button1'
        this.button1InputWidget.enable(false)
        this.button1InputWidget.value('')
      }
    },
    toggleButton2: function () {
      this.activateAccept2 = !this.activateAccept2
      if (this.activateAccept2) {
        this.$refs.button2.className = 'button2-exclude'
        this.$refs.button1.className = 'button1'
        this.$refs.button3.className = 'button3'
        this.button2InputWidget.enable(true)
        this.button1InputWidget.enable(false)
        this.button1InputWidget.value('')
        this.activateAccept1 = false
        this.activateAccept3 = false
      } else {
        this.$refs.button2.className = 'button2'
        this.button2InputWidget.enable(false)
        this.button2InputWidget.value('')
      }
    },
    toggleButton3: function () {
      this.activateAccept3 = !this.activateAccept3
      if (this.activateAccept3) {
        this.$refs.button3.className = 'button3-accept'
        this.$refs.button1.className = 'button1'
        this.$refs.button2.className = 'button2'
        this.button1InputWidget.enable(false)
        this.button1InputWidget.value('')
        this.button2InputWidget.enable(false)
        this.button2InputWidget.value('')
        this.activateAccept1 = false
        this.activateAccept2 = false
      } else {
        this.$refs.button3.className = 'button3'
      }
    }
  },
  data () {
    return {
      button1InputWidget: {},
      button2InputWidget: {},
      button3InputWidget: {},
      actions: [
        { label: 'Current Stage:', value: 'Abstract Screening', type: 'text', style: 'background: #FFFFFF' },
        { label: 'Push to:', value: 'Added Materials', type: 'accept', style: 'background: #bde0a6; padding: 5px;' },
        { label: 'Exclusion Reason:', selectedValue: 'Animal in Vitro', type: 'reject', style: 'background: #db6664; color: #000000; padding: 5px;' }
      ],
      stageDataSource: StageDataSource,
      ExclusionReasonDataSource: ExclusionReasonDataSource
    }
  },
  mounted: function () {
    this.button1InputWidget = this.$refs.button1Input.kendoWidget()
    this.button2InputWidget = this.$refs.button2Input.kendoWidget()
    if (!this.$store.state.isFirstActionVisualization) {
      this.button1InputWidget.enable(false)
      this.button2InputWidget.enable(false)
    }
  }
}
</script>

<style>
  .collapsible {
  color: #444;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, .collapsible:hover {
  background-color: #ccc;
}

/* Style the collapsible content. Note: hidden by default */
.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #f1f1f1;
}
</style>
