<template>
  <div class='forms' @click='setFormEntryFocus()'>
    <kendo-datasource :ref="'studySelectorDataSource'" :data="studyDataSource.data">
    </kendo-datasource>
    <div style="display: flex; flex-direction: row;">
      <div>
        <label>Study:</label>
        <kendo-dropdownlist :id="'studyMappingSelector'" :data-source-ref="'studySelectorDataSource'"
          :data-value-field="'StudyIdentity'" :data-text-field="'StudyAcronym'">
        </kendo-dropdownlist>
      </div>
      <div>
        <label>Form:</label>
        <kendo-dropdownlist :id="'studyMappingSelector'" :data-source-ref="'studySelectorDataSource'"
          :data-value-field="'StudyIdentity'" :data-text-field="'StudyAcronym'">
        </kendo-dropdownlist>
      </div>
    </div>
    <survey :survey='survey'></survey>
  </div>
</template>

<script>
import StudyDataSource from '@/assets/data/Study.json'
import * as SurveyVue from 'survey-vue'
import $ from 'jquery'
var Survey = SurveyVue.Survey
Survey.cssType = 'bootstrap'
var _surveyData = ''

export default {
  name: 'form-entry',
  components: {
    Survey
  },
  props: ['type'],
  data () {
    return {
      survey: {},
      formDataSource: this.$store.state.surveyDesignerDummyTest,
      studyDataSource: StudyDataSource,
      selectedIndex: 0
    }
  },
  methods: {
    setFormEntryFocus: function () {
      this.$store.state.currentFocus = 'Form Entry'
    },
    setForm: function (e) {
      this.selectedIndex = e.sender.selectedIndex - 1
      this.survey = new SurveyVue.Model(this.formDataSource.pages[this.selectedIndex])

      setTimeout(function () {
        var questionDiv = $('div[class=sv_row]:contains("Treatment")').find('thead')
        questionDiv.prepend('<tr style="text-align: left;"><th class="q_headers"></th><th colspan="2" class="q_headers">SG 1</th><th colspan="2" class="q_headers">SG 2</th><th colspan="2" class="q_headers">SG 1</th><th colspan="2" class="q_headers">SG 2</th><th colspan="2" class="q_headers">SG 1</th><th colspan="2" class="q_headers">SG 2</th></tr>')
        questionDiv.prepend('<tr style="text-align: left"><th class="q_headers"></th><th colspan="4" class="q_headers">Arm 1: Human IgG4 monoclonal antibody</th><th colspan="4" class="q_headers">Arm 2: nivolumab</th><th colspan="4" class="q_headers">Arm 3: nivolumab + ipilimumab</th></tr>')
      }, 2000)
    }
  },
  created: function () {
    this.survey = new SurveyVue.Model(this.formDataSource)
    // this.survey = new SurveyVue.Model(FormDataSource.data[this.selectedIndex].Content)

    console.log(this.survey)
    if (_surveyData.length > 0) {
      this.survey.data = JSON.parse(_surveyData)
    }
    this.survey.onCurrentPageChanged.add(function (result) {
      _surveyData = JSON.stringify(result.data)
      console.log(_surveyData)
    })
    this.survey.onComplete.add(function (result) {
      _surveyData = JSON.stringify(result.data)
      console.log(_surveyData)
    })
  }
}
</script>

<style>
  .q_headers {
    border-right: solid 1px gray !important;
  }
</style>
