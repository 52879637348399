<template>
  <div :data-uid="templateArgs.uid">
    <div class="citation-questions">
      <div class="citation-item">
        <div class="citation-label">Citation ID:</div>
        <div class="citation-value">{{ templateArgs.PublicationIdentity }}</div>
      </div>
      <div class="citation-item">
        <div class="citation-label">Author:</div>
        <div class="citation-value">{{ templateArgs.Author }}</div>
      </div>
      <div class="citation-item">
        <div class="citation-label">Year:</div>
        <div class="citation-value">{{ templateArgs.Year }}</div>
      </div>
      <div class="citation-item">
        <div class="citation-label">Title:</div>
        <div class="citation-value">{{ templateArgs.Title }}</div>
      </div>
      <div class="citation-item">
        <div class="citation-label">Type Of Publication:</div>
        <div class="citation-value">{{ templateArgs.TypeOfPublication }}</div>
      </div>
      <div class="citation-item">
        <div class="citation-label">Name of Database:</div>
        <div class="citation-value">{{ templateArgs.NameOfDatabase }}</div>
      </div>
      <div class="citation-item">
        <div class="citation-label">Accession Number:</div>
        <div class="citation-value">{{ templateArgs.AccessionNumber }}</div>
      </div>
      <div class="citation-item">
        <div class="citation-label">DOI:</div>
        <div class="citation-value">{{ templateArgs.DOI }}</div>
      </div>
      <div class="citation-item">
        <div class="citation-label">Studies:</div>
        <div class="citation-value">{{ templateArgs.StudyID }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'citation-view',
  data () {
    return {
      templateArgs: {}
    }
  }
}
</script>
