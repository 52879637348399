<template>
  <div class="action" @click="setActionsFocus()">
    <div style="display: flex; flex-direction: row; justify-content: space-between; padding-right: 20px;">
      <a href="#" style="width: 200px; text-decoration: underline; font-size: 0.8em;">Swap</a>
      &nbsp;
    </div>
    <div class="second-action-container" v-if="!this.$store.state.isFirstActionVisualization">
      <div class="label-container">
        <label><b>Current Stage:</b> {{ currentStageName }}</label>
        <label><b>Review Status:</b> </label>
      </div>
      <div class="button-container-de" ref="button1Container">
        <button class="button1" @click="toggleButton1" @shortkey="toggleButton1" ref="button1">
            <h3 ref="button1Title"><u>I</u>nclude (Ctrl+I)</h3>
            <h4 ref="button1Label">Push to:</h4>
            <div v-on:click.stop>
              <kendo-dropdownlist
                class="button1-input"
                :ref = "'button1Input'"
                :data-source="stageDataSource"
                :data-text-field="'StageName'"
                :data-value-field="'StageIdentity'">
              </kendo-dropdownlist>
            </div>
        </button>
        <button class="button2" @click="toggleButton2" @shortkey="toggleButton2" ref="button2">
            <h3 ref="button2Title"><u>E</u>xclude (Ctrl+E)</h3>
            <h4 ref="button2Label">Reason(s):</h4>
            <div v-on:click.stop>
              <kendo-multiselect
                class="button2-input"
                :ref = "'button2Input'"
                :data-source="ExclusionReasonDataSource"
                :data-value-field="'ExclusionReasonIdentity'"
                :data-text-field="'ExclusionReasonName'"
                :placeholder="'Select reason...'">
                </kendo-multiselect>
            </div>
        </button>
        <button style="background-color: white;">
        <a href="#" style="width: 200px; text-decoration: underline; font-size: 0.8em;">Rollback to stage: Stage xxx</a>
        </button>
      </div>
    </div>
    <div>
      <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;">
        <kendo-button>Next</kendo-button>
        <a href="#" class="k-button k-button-icontext k-grid-excel" @click="exit()">Exit Review</a>
      </div>
    </div>
  </div>
</template>

<script>

import StageDataSource from '@/assets/data/Stage.json'
import ExclusionReasonDataSource from '@/assets/data/ExclusionReason.json'
import AnnotationDataSource from '@/assets/data/Annotation.json'
import AnnotationFilterOptionDataSource from '@/assets/data/AnnotationFilterOption.json'
import $ from 'jquery'
import mitt from 'mitt'
const emitter = mitt()

export default {
  name: 'actions-manage-stages-forms',
  props: ['type'],
  methods: {
    exit: function () {
      $('.hdr-navigation-item').css('cursor', 'pointer')
      $('.hdr-title-text').css('cursor', 'pointer')
      $('.hdr-title').css('cursor', 'pointer')
      this.$store.state.selectedAction = 'select'
      this.$store.state.cardSelector = false
      this.$router.push({ path: '/secure/StudyMapping' })
    },
    setActionsFocus: function () {
      this.$store.state.currentFocus = 'Actions'
    },
    addAnnotation: function (section, highlightedText, comment) {
      this.annotationDataSource.data.push(
        {
          AnnotationIdentity: (this.annotationDataSource.data.length + 1),
          Author: this.$store.state.logUser,
          Date: new Date(),
          StageName: this.$store.state.currentStage,
          Section: section,
          HighlightedText: highlightedText,
          Comment: comment
        }
      )
      this.$refs.annotationDataSource.kendoWidget().sort({ field: 'AnnotationIdentity', dir: 'desc' })
    },
    deleteAnnotation: function () {
      // console.log(this.$refs.annotationsListView.kendoWidget().element.children())
      // var index = this.$refs.annotationsListView.kendoWidget().select().index()
      // alert(index)
    },
    changeAnnotationSelection: function (e) {
      var selectedIndex = e.sender.select().index()
      var dataItem = this.$refs.annotationDataSource.kendoWidget().view()[selectedIndex]
      emitter.emit('sendHighlightedText', dataItem.Section, dataItem.HighlightedText)
    },
    itemTemplate: function () {
      return `<div class="k-widget action-annotations-item">
            <dl>
              <dd><b>#= kendo.toString(kendo.parseDate(Date), 'MM/dd/yyyy')# - #:StageName# - #:Section# - #:Author#</b>  #if (HighlightedText.length>0) {# "#:HighlightedText#" #}#  #:Comment#</dt>
            </dl>
          </div>`
    },
    editTemplate: function () {
      return `<div class="product-view k-widget">
            <dl>
              <dt>Annotation</dt>
              <dd>
                <input type="text" class="k-textbox" data-bind="value:Author" name="AnnotationTitle" required="required" validationMessage="required" />
                <span data-for="AnnotationTitle" class="k-invalid-msg"></span>
              </dd>
            </dl>
            <div>
              <a class="k-update-button" href="\\\\#">Save</span></a>
              <a class="k-cancel-button" href="\\\\#">Close</span></a>
            </div>
          </div>`
    },
    toggleButton1: function () {
      this.activateAccept1 = !this.activateAccept1
      if (this.activateAccept1) {
        this.$refs.button1.className = 'button1-include'
        this.$refs.button2.className = 'button2'
        this.$refs.button3.className = 'button3'
        this.button1InputWidget.enable(true)
        this.button2InputWidget.enable(false)
        this.button2InputWidget.value('')
        this.activateAccept2 = false
        this.activateAccept3 = false
      } else {
        this.$refs.button1.className = 'button1'
        this.button1InputWidget.enable(false)
        this.button1InputWidget.value('')
      }
    },
    toggleButton2: function () {
      this.activateAccept2 = !this.activateAccept2
      if (this.activateAccept2) {
        this.$refs.button2.className = 'button2-exclude'
        this.$refs.button1.className = 'button1'
        this.$refs.button3.className = 'button3'
        this.button2InputWidget.enable(true)
        this.button1InputWidget.enable(false)
        this.button1InputWidget.value('')
        this.activateAccept1 = false
        this.activateAccept3 = false
      } else {
        this.$refs.button2.className = 'button2'
        this.button2InputWidget.enable(false)
        this.button2InputWidget.value('')
      }
    },
    toggleButton3: function () {
      this.activateAccept3 = !this.activateAccept3
      if (this.activateAccept3) {
        this.$refs.button3.className = 'button3-accept'
        this.$refs.button1.className = 'button1'
        this.$refs.button2.className = 'button2'
        this.button1InputWidget.enable(false)
        this.button1InputWidget.value('')
        this.button2InputWidget.enable(false)
        this.button2InputWidget.value('')
        this.activateAccept1 = false
        this.activateAccept2 = false
      } else {
        this.$refs.button3.className = 'button3'
      }
    }
  },
  data () {
    return {
      button1InputWidget: {},
      button2InputWidget: {},
      button3InputWidget: {},
      activateInclude: false,
      activateExclude: false,
      stageDataSource: StageDataSource,
      ExclusionReasonDataSource: ExclusionReasonDataSource,
      annotationDataSource: AnnotationDataSource,
      annotationFilterOptionDataSource: AnnotationFilterOptionDataSource,
      annotationsListViewConfiguration: {
        itemTemplate: this.itemTemplate(),
        editTemplate: this.editTemplate()
      },
      currentStageName: 'Data Extraction',
      isStudyMapping: this.$store.state.isStudyMapping
    }
  },
  mounted: function () {
    emitter.on('addAnnotation', (section, highlightedText, comment) => {
      this.addAnnotation(section, highlightedText, comment)
    })
    this.button1InputWidget = this.$refs.button1Input.kendoWidget()
    this.button2InputWidget = this.$refs.button2Input.kendoWidget()
    if (!this.$store.state.isFirstActionVisualization) {
      this.button1InputWidget.enable(false)
      this.button2InputWidget.enable(false)
    }
    if (this.$store.state.isStudyMapping) {
      this.currentStageName = 'Study Mapping'
      this.$refs.button2.className = 'button2-disabled'
      // this.$refs.button1Container.className = 'button-container-disabled'
    }
  },
  unmounted: function () {
    emitter.off('addAnnotation')
  }
}
</script>
