<template>
  <div class="abstract-container k-edit-item" :data-uid="templateArgs.uid" data-role="editable">
    <div class="citation-container">
      <div class="citation-questions">
        <div class="citation-item">
          <div class="citation-label">Citation ID:</div>
          <input type="text" class="k-textbox" style="width: 100%" name="PublicationIdentity" required="required" validationMessage="required" :value="templateArgs.PublicationIdentity" />
        </div>
        <div class="citation-item">
          <div class="citation-label">Author:</div>
          <input type="text" class="k-textbox" style="width: 100%" name="Authors" required="required" validationMessage="required" :value="templateArgs.Author" />
        </div>
        <div class="citation-item">
          <div class="citation-label">Year:</div>
          <input type="text" class="k-textbox" style="width: 100%" name="Year" required="required" validationMessage="required" :value="templateArgs.Year" />
        </div>
        <div class="citation-item">
          <div class="citation-label">Title:</div>
          <input type="text" class="k-textbox" style="width: 100%" name="Title" required="required" validationMessage="required" :value="templateArgs.Title" />
        </div>
        <div class="citation-item">
          <div class="citation-label">Type Of Publication:</div>
          <input type="text" class="k-textbox" style="width: 100%" name="TypeOfPublication" required="required" validationMessage="required" :value="templateArgs.TypeOfPublication" />
        </div>
        <div class="citation-item">
          <div class="citation-label">Name of Database:</div>
          <input type="text" class="k-textbox" style="width: 100%" name="TypeOfPublication" required="required" validationMessage="required" :value="templateArgs.NameOfDatabase" />
        </div>
        <div class="citation-item">
          <div class="citation-label">Accession Number:</div>
          <input type="text" class="k-textbox" style="width: 100%" name="AccessionNumber" required="required" validationMessage="required" :value="templateArgs.AccessionNumber" />
        </div>
        <div class="citation-item">
          <div class="citation-label">DOI:</div>
          <input type="text" class="k-textbox" style="width: 100%" name="DOI" required="required" validationMessage="required" :value="templateArgs.DOI" />
        </div>
        <div class="citation-item">
          <div class="citation-label">Studies:</div>
          <div style="display: flex; flex-direction: row;">
            <div>
              <kendo-datasource
                :ref="'availableStudyDataSource'"
                :data="studyDataSource.data">
              </kendo-datasource>
              Available Studies
              <kendo-grid
                :id="'availableStudyGrid'"
                :ref="'availableStudyGrid'"
                :data-source-ref="'availableStudyDataSource'"
                :selectable="selectable">
                <kendo-grid-column field="StudyID" title="Study ID"></kendo-grid-column>
                <kendo-grid-column field="StudyAcronym" title="Acronym"></kendo-grid-column>
                <kendo-grid-column field="StudyDescription" title="Description"></kendo-grid-column>
              </kendo-grid>
              <div>
                <a href="#" @click="studyNew">Add</a>
                <a href="#">Edit</a>
                <a href="#">Delete</a>
              </div>
            </div>
            <div style="display: flex; flex-direction: column; padding-left: 5px; padding-right: 5px;">
              <a href="#" class="k-button k-button-icon">
                <span class="k-icon k-i-arrow-60-right"></span>
              </a>
              <a href="#" class="k-button k-button-icon">
                <span class="k-icon k-i-arrow-60-left"></span>
              </a>
            </div>
            <div>
              <kendo-datasource
                :ref="'selectedStudyDataSource'"
                :data="studyDataSource.data"
                :filter="filter">
              </kendo-datasource>
              Selected Studies
              <kendo-grid
                :id="'selectedStudyGrid'"
                :ref="'selectedStudyGrid'"
                :data-source-ref="'selectedStudyDataSource'"
                :selectable="selectable">
                <kendo-grid-column field="StudyID" title="Selected Study ID"></kendo-grid-column>
                <kendo-grid-column field="StudyAcronym" title="Acronym"></kendo-grid-column>
                <kendo-grid-column field="StudyDescription" title="Description"></kendo-grid-column>
                <kendo-grid-column field="PrimaryPublication" title="Primary Publication"></kendo-grid-column>
              </kendo-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
    <kendo-window :ref="'studyNewWindow'"
                  :width="windowWidth"
                  :title="windowTitle"
                  :visible="windowVisible"
                  :modal="true"
                  :top="'0px'">
      <component class="component" :is="component"></component>
    </kendo-window>
  </div>
</template>

<script>
import StudyDataSource from '@/assets/data/Study.json'
import StudyNew from '@/views/secure/cards/templates/StudyNew.vue'

export default {
  name: 'citation-edit',
  components: {
    StudyNew
  },
  methods: {
    selectStudyID: function () {
      this.$refs.StudyAcronym.value = this.$refs.studyDataSource.kendoWidget().at(1).StudyAcronym
      this.$refs.StudyDescription.value = this.$refs.studyDataSource.kendoWidget().at(1).StudyDescription
    },
    studyNew: function () {
      var windowWidget = this.$refs.studyNewWindow.kendoWidget()
      this.windowTitle = 'New Study'
      this.windowWidth = '1200px'
      this.component = 'StudyNew'
      this.windowVisible = true
      windowWidget.close()
      windowWidget.center().open()
    }
  },
  data () {
    return {
      windowTitle: '',
      windowWidth: '',
      windowVisible: false,
      component: '',
      templateArgs: {},
      autocompleteValue: '',
      studyDataSource: StudyDataSource,
      selectable: true,
      filter: {
        logic: 'or',
        filters: [
          { field: 'StudyID', operator: 'eq', value: 'NCT00588770' },
          { field: 'StudyID', operator: 'eq', value: 'NCT01345682' }
        ]
      }
    }
  }
}
</script>
