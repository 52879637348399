<template>
  <div class="abstract" @click="setAbstractFocus()">
    <kendo-datasource
      :ref="'abstractDataSource'"
      :data="publicationDataSource.data"
      :filter="filter">
    </kendo-datasource>
    <kendo-listview
      class="abstract-listview"
      :ref="'abstractListView'"
      :data-source-ref="'abstractDataSource'"
      :template="itemTemplate"
      :edit-template="editTemplate">
    </kendo-listview>
  </div>
</template>

<script>
import CitationDataSource from '@/assets/data/Citation.json'
import mitt from 'mitt'
const emitter = mitt()

export default {
  name: 'abstract',
  props: ['editable'],
  methods: {
    setAbstractFocus: function () {
      this.$store.state.currentFocus = 'Abstract'
    },
    generateItemTemplate: function () {
      var itemTemplateStart = `
        <div class="abstract-container">
          <div class="abstract-questions" id="abstractText">
            #:Abstract#
          </div>
          <div class="abstract-options">`
      var itemTemplateMiddle = ''
      var itemTemlateEnd = `
          </div>
            </div>`

      if (this.editable) {
        return itemTemplateStart + itemTemplateMiddle + itemTemlateEnd
      } else {
        return itemTemplateStart + itemTemlateEnd
      }
    },
    generateEditTemplate: function () {
      return ''
    },
    setHighlightedText: function (type, text) {
      if (type === 'Abstract') {
        var abstractHighlightedText = this.$options.filters.highlight(this.abstractDataSource[0].Abstract, text)
        document.getElementById('abstractText').innerHTML = abstractHighlightedText
      }
    }
  },
  data () {
    return {
      itemTemplate: this.generateItemTemplate(),
      editTemplate: this.generateEditTemplate(),
      CitationDataSource: CitationDataSource,
      filter: { field: 'CitationIdentity', operator: 'eq', value: this.$store.state.currentCitationId }
    }
  },
  created: function () {
    emitter.on('sendHighlightedText', (type, text) => {
      this.setHighlightedText(type, text)
    })
  }
}
</script>
