<template>
  <div class="citation" @click="setCitationFocus()">
    <div class="citation-container">
      <div class="citation-questions">
        <kendo-datasource
          :ref="'citationDataSource'"
          :data="publicationDataSource.data"
          :filter="filter">
        </kendo-datasource>
        <kendo-listview
          :id="'citationListView'"
          class="citation-listview"
          :ref="'citationListView'"
          :auto-bind="autoBind"
          :data-source-ref="'citationDataSource'"
          :template="citationViewTemplate"
          :edit-template="citationEditTemplate">
        </kendo-listview>
      </div>
      <div class="citation-options" v-if="editable === 'true'">
        <div class="activeLink">Show/Hide Keywords</div>
        <div @click="editCitation" :class="toggleEditAnchor()">Edit</div>
        <div style="display: flex; flex-direction: row; justify-content: space-around; width: 70px;">
          <div @click="applyCitation" :class="toggleApplyAnchor()">Apply</div>
          <div @click="cancelCitation" :class="toggleApplyAnchor()">Cancel</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PublicationDataSource from '@/assets/data/Citation.json'
import CitationViewTemplate from './templates/CitationView'
import CitationEditTemplate from './templates/CitationEdit'
import mitt from 'mitt'
const emitter = mitt()

export default {
  name: 'citation',
  props: ['editable'],
  methods: {
    citationViewTemplate: function (e) {
      return {
        template: CitationViewTemplate,
        templateArgs: e
      }
    },
    citationEditTemplate: function (e) {
      return {
        template: CitationEditTemplate,
        templateArgs: e
      }
    },
    editCitation: function () {
      this.isEdit = true
      var citationWidget = this.$refs.citationListView.kendoWidget()
      citationWidget.edit(citationWidget.element.children().first())
    },
    applyCitation: function () {
      this.isEdit = false
      var citationWidget = this.$refs.citationListView.kendoWidget()
      citationWidget.save()
    },
    cancelCitation: function () {
      this.isEdit = false
      var citationWidget = this.$refs.citationListView.kendoWidget()
      citationWidget.cancel()
    },
    toggleApplyAnchor: function () {
      return this.isEdit ? 'activeLink' : 'inactiveLink'
    },
    toggleEditAnchor: function () {
      return !this.isEdit ? 'activeLink' : 'inactiveLink'
    },
    setCitationFocus: function () {
      this.$store.state.currentFocus = 'Citation'
    },
    setHighlightedText: function (type) {
      if (type === 'Citation') {
        // var citationHighlightedText = this.$options.filters.highlight(this.citationDataSource[0].Abstract, text)
        // document.getElementById('citationText').innerHTML = citationHighlightedText
      }
    }
  },
  data () {
    return {
      isEdit: false,
      autoBind: true,
      publicationDataSource: PublicationDataSource,
      filter: { field: 'PublicationIdentity', operator: 'eq', value: this.$store.state.currentPublicationId },
      isStudyMapping: this.$store.state.isStudyMapping
    }
  },
  created: function () {
    emitter.on('sendHighlightedText', (type, text) => {
      this.setHighlightedText(type, text)
    })
  },
  mounted: function () {
    if (this.isStudyMapping) {
      this.isEdit = true
      var citationWidget = this.$refs.citationListView.kendoWidget()
      citationWidget.edit(citationWidget.element.children().first())
    }
  }
}
</script>
