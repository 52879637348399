<template>
  <div class="full-text" @click="setFullTextFocus()">
    <kendo-dropdownlist
            :data-source="documentDataSource"
            data-value-field="DocumentIdentity"
            data-text-field="DocumentName">
          </kendo-dropdownlist>
    <!-- <embed
      class="full-text-pdf"
      src="static/study.pdf"
      type="application/pdf"/> -->
    <div class="full-text-options">
      <a href="\\\\#">Show/Hide Keywords</a>
      <a href="\\\\#">Import Full Text</a>
    </div>
  </div>
</template>

<script>
import DocumentDataSource from '@/assets/data/Document.json'

export default {
  name: 'full-text',
  props: ['type'],
  methods: {
    setFullTextFocus: function () {
      this.$store.state.currentFocus = 'Full Text'
    }
  },
  data () {
    return {
      documentDataSource: DocumentDataSource
    }
  }
}
</script>
