<template>
  <div class="filters">
    <div class="filters-wrapper">
      <div class="filters-title">Main Citation Fields</div>
      <div class="filters-control">
        <kendo-multiselect
          :ref="'citationMultiSelect'"
          :data-source="citationDataSource"
          :data-text-field="'text'"
          :data-value-field="'value'"
          :open="openCitation"
          :deselect="deselectCitation"
          :placeholder="'No fields selected...'">
        </kendo-multiselect>
      </div>
      <div class="filters-title">Study Fields</div>
      <div class="filters-control">
        <kendo-multiselect
          :ref="'studyMultiSelect'"
          :data-source="studyDataSource"
          :data-text-field="'text'"
          :data-value-field="'value'"
          :open="openStudy"
          :deselect="deselectStudy"
          :placeholder="'No fields selected...'">
        </kendo-multiselect>
      </div>
      <div class="filters-title">Other Citation Fields</div>
      <div class="filters-control">
        <kendo-multiselect
          :ref="'otherPublicationMultiSelect'"
          :data-source="otherPublicationDataSource"
          :data-text-field="'text'"
          :data-value-field="'value'"
          :open="openOtherPublication"
          :deselect="deselectOtherPublication"
          :placeholder="'No fields selected...'">
        </kendo-multiselect>
      </div>
      <!-- <div class="filters-title">Current Stage</div>
      <div class="filters-control">
        <kendo-dropdownlist
          :data-source="stageDataSource"
          :data-value-field="'StageIdentity'"
          :data-text-field="'StageName'"
          :option-label="'All'">
        </kendo-dropdownlist>
      </div> -->
      <div class="filters-title">Annotations</div>
      <div class="filters-control">
        <kendo-dropdownlist
          :data-source="annotationStatusDataSource"
          :data-value-field="'AnnotationStatusIdentity'"
          :data-text-field="'AnnotationStatusName'"
          :option-label="'All'">
        </kendo-dropdownlist>
      </div>
      <!-- <div class="filters-title">Publication Extraction Status</div>
      <div class="filters-control">
        <kendo-dropdownlist
          :ref="'publicationFilter'"
          :data-source="publicationFilterDataSource"
          :data-text-field="'text'"
          :data-value-field="'value'"
          :auto-width="true">
        </kendo-dropdownlist>
      </div> -->
      <!-- <div class="filters-title">Keywords</div>
      <div class="filters-control">
        <kendo-multiselect
          :data-source="keywordDataSource"
          :data-value-field="'KeywordIdentity'"
          :data-text-field="'KeywordName'"
          :placeholder="'Select keywords...'">
        </kendo-multiselect>
        <kendo-multiselect
          :data-source="keywordSearchDataSource"
          :data-text-field="'text'"
          :data-value-field="'value'"
          :placeholder="'Found in...'">
        </kendo-multiselect>
      </div> -->
      <!-- <div class="filters-title">SORTED BY</div>
      <div class="filters-control">
        <kendo-multiselect
          :data-source="sortDataSource"
          :data-text-field="'text'"
          :data-value-field="'value'"
          :max-selected-items="maxSelectedItems"
          :placeholder="'Select sort...'">
        </kendo-multiselect>
      </div> -->
      <div class="filters-title">Show Citations with Annotations that contain</div>
      <div class="citation-label">
        <kendo-maskedtextbox>
        </kendo-maskedtextbox>
      </div>
      <div class="action-buttons-nav">
        <b-button type="submit" variant="primary" size="sm">Clear All</b-button>
        <b-button type="submit" variant="primary" size="sm">Search</b-button>
      </div>
            <div class="panel-header">
        <div class="panel-header-title">Quick Filter:</div>
      </div>
      <div class="filters-title">Review Status</div>
      <div class="filters-control">
        <kendo-dropdownlist
          :data-source="reviewStatusDataSource"
          :data-value-field="'ReviewStatusIdentity'"
          :data-text-field="'ReviewStatusName'"
          :option-label="'All'">
        </kendo-dropdownlist>
      </div>
      <div class="filters-title">Stage</div>
      <div class="filters-control">
        <kendo-dropdownlist
          :data-source="stageDataSource"
          :data-value-field="'StageIdentity'"
          :data-text-field="'StageName'"
          :option-label="'All'">
        </kendo-dropdownlist>
      </div>
      <div class="filters-title">Sort by</div>
      <div class="filters-control">
        <kendo-dropdownlist
          :data-source="citationDataSource"
          :data-value-field="'text'"
          :data-text-field="'value'"
          :option-label="'All'">
        </kendo-dropdownlist>
      </div>
      <div class="action-buttons-nav">
        <b-button type="submit" variant="primary" size="sm">Quick Review</b-button>
      </div>
      <div class="filters-actions">
        <div class="filters-actions-items" v-for="item in filterActions" :key="item.text" @click="filterAction(item.text)">
              {{ item.text }}
        </div>
      </div>
    </div>
    <kendo-window :ref="'detachWindow'"
                  :width="windowWidth"
                  :title="windowTitle"
                  :visible="windowVisible"
                  :modal="true"
                  :top="'0px'">
        <component class="component" :is="component"></component>
    </kendo-window>
  </div>
</template>

<script>
import FormDataSource from '@/assets/data/Form.json'
import FormStatusDataSource from '@/assets/data/FormStatus.json'
import AnnotationStatusDataSource from '@/assets/data/AnnotationStatus.json'
import StageDataSource from '@/assets/data/Stage.json'
import ReviewStatusDataSource from '@/assets/data/ReviewStatus.json'
import KeywordDataSource from '@/assets/data/Keyword.json'
import FilterOpen from '@/views/secure/cards/templates/FilterOpen.vue'
import FilterSave from '@/views/secure/cards/templates/FilterSave.vue'
import mitt from 'mitt'
const emitter = mitt()

export default {
  name: 'filters',
  props: ['selected'],
  components: {
    FilterOpen,
    FilterSave
  },
  created: function () {
    emitter.on('addedCitationFilter', (filterValue, filterClear) => {
      if (filterClear !== null) {
        this.$store.state.selectedCitationFilters.push(filterValue)
        this.citationWidget.value(this.$store.state.selectedCitationFilters)
      } else {
        this.$store.state.selectedCitationFilters = this.$store.state.selectedCitationFilters.filter(item => item !== filterValue)
        this.citationWidget.value(this.$store.state.selectedCitationFilters)
      }
      if (this.$store.state.selectedCitationFilters.length > 0) {
        this.filtersWidget.expand('.k-item:eq(0)')
      } else {
        this.filtersWidget.collapse('.k-item:eq(0)')
      }
    })
    emitter.on('addedStudyFilter', (filterValue, filterClear) => {
      if (filterClear !== null) {
        this.$store.state.selectedStudyFilters.push(filterValue)
        this.studyWidget.value(this.$store.state.selectedStudyFilters)
      } else {
        this.$store.state.selectedStudyFilters = this.$store.state.selectedStudyFilters.filter(item => item !== filterValue)
        this.studyWidget.value(this.$store.state.selectedStudyFilters)
      }
      if (this.$store.state.selectedStudyFilters.length > 0) {
        this.filtersWidget.expand('.k-item:eq(1)')
      } else {
        this.filtersWidget.collapse('.k-item:eq(1)')
      }
    })
    emitter.on('addedOtherPublicationFilter', (filterValue, filterClear) => {
      if (filterClear !== null) {
        this.$store.state.selectedOtherPublicationFilters.push(filterValue)
        this.otherPublicationWidget.value(this.$store.state.selectedOtherPublicationFilters)
      } else {
        this.$store.state.selectedOtherPublicationFilters = this.$store.state.selectedOtherPublicationFilters.filter(item => item !== filterValue)
        this.otherPublicationWidget.value(this.$store.state.selectedOtherPublicationFilters)
      }
      if (this.$store.state.selectedOtherPublicationFilters.length > 0) {
        this.filtersWidget.expand('.k-item:eq(2)')
      } else {
        this.filtersWidget.collapse('.k-item:eq(2)')
      }
    })
  },
  mounted: function () {
    // this.filtersWidget = this.$refs.filtersPanelBar.kendoWidget()
    this.citationWidget = this.$refs.citationMultiSelect.kendoWidget()
    this.studyWidget = this.$refs.studyMultiSelect.kendoWidget()
    this.otherPublicationWidget = this.$refs.otherPublicationMultiSelect.kendoWidget()
  },
  methods: {
    openCitation: function (e) {
      e.preventDefault()
    },
    openStudy: function (e) {
      e.preventDefault()
    },
    openOtherPublication: function (e) {
      e.preventDefault()
    },
    deselectCitation: function (e) {
    },
    deselectStudy: function (e) {
    },
    deselectOtherPublication: function (e) {
    },
    filterAction: function (action) {
      var windowWidget = this.$refs.detachWindow.kendoWidget()
      switch (action) {
        case 'Open':
          this.windowTitle = 'Open Filter'
          this.windowWidth = '800px'
          this.component = 'FilterOpen'
          this.windowVisible = true
          windowWidget.close()
          windowWidget.center().open()
          break
        case 'Save':
          this.windowTitle = 'Save Filter'
          this.windowWidth = '800px'
          this.component = 'FilterSave'
          this.windowVisible = true
          windowWidget.close()
          windowWidget.center().open()
          break
      }
    }
  },
  data () {
    return {
      windowTitle: '',
      windowWidth: '',
      windowVisible: false,
      component: '',
      filtersWidget: '',
      citationWidget: '',
      OtherPublicationWidget: '',
      multiselectValue: '',
      maxSelectedItems: 1,
      citationDataSource: [
        { value: 'PublicationIdentity', text: 'Publication ID', type: 'citation' },
        { value: 'Author', text: 'Author', type: 'citation' },
        { value: 'Year', text: 'Year', type: 'citation' },
        { value: 'Title', text: 'Title', type: 'citation' },
        { value: 'Abstract', text: 'Abstract', type: 'citation' },
        { value: 'TypeOfPublication', text: 'TypeOfPublication', type: 'citation' },
        { value: 'Volume', text: 'Volume', type: 'citation' },
        { value: 'Issue', text: 'Issue', type: 'citation' },
        { value: 'Pages', text: 'Pages', type: 'citation' },
        { value: 'ConferenceName', text: 'Conference Name', type: 'citation' },
        { value: 'ConferenceDate', text: 'Date(s) of Conference', type: 'citation' },
        { value: 'TypeOfPublication', text: 'TypeOfPublication', type: 'citation' },
        { value: 'ReportName', text: 'Report Name', type: 'citation' },
        { value: 'OrganizingBody', text: 'Organizing Body', type: 'citation' },
        { value: 'ReportDate', text: 'Date of Report', type: 'citation' },
        { value: 'DatabaseName', text: 'Name of Database', type: 'citation' },
        { value: 'AccessionNumber', text: 'Accession Number', type: 'citation' },
        { value: 'DOI', text: 'DOI', type: 'citation' }
      ],
      studyDataSource: [
        { value: 'StudyIdentity', text: 'Study ID', type: 'study' },
        { value: 'StudyAcronym', text: 'Study Acronym', type: 'study' },
        { value: 'StudyDescription', text: 'Study Description', type: 'study' }
      ],
      publicationFilterDataSource: [
        { value: '1', text: 'All', type: 'study' },
        { value: '2', text: 'Complete', type: 'study' },
        { value: '3', text: 'Incomplete', type: 'study' }
      ],
      studyFilterDataSource: [
        { value: '1', text: 'All', type: 'study' },
        { value: '2', text: 'Complete', type: 'study' },
        { value: '3', text: 'Incomplete', type: 'study' }
      ],
      dataExtractionFilterDataSource: [
        { value: '1', text: 'All', type: 'study' },
        { value: '2', text: '?', type: 'study' }
      ],
      otherPublicationDataSource: [
        { value: 'CurrentStage', text: 'Current Stage', type: 'other' },
        { value: 'MovedFrom', text: 'Moved From', type: 'other' },
        { value: 'MovedBy', text: 'Moved By', type: 'other' },
        { value: 'DateMoved', text: 'Date Moved', type: 'other' },
        { value: 'AccessionNumber', text: 'Accession Number', type: 'other' },
        { value: 'DOI', text: 'DOI', type: 'other' }
      ],
      stageDataSource: StageDataSource,
      annotationStatusDataSource: AnnotationStatusDataSource,
      formDataSource: FormDataSource,
      formStatusDataSource: FormStatusDataSource,
      keywordDataSource: KeywordDataSource,
      reviewStatusDataSource: ReviewStatusDataSource,
      keywordSearchDataSource: [
        { value: 'Title', text: 'Title' },
        { value: 'Abstract', text: 'Abstract' },
        { value: 'FullText', text: 'Full Text' }
      ],
      sortDataSource: [
        { value: 'AllIncludedKeywords', text: 'Keywords in Filter - Desc', type: 'sort' },
        { value: 'AllIncludedKeywords', text: 'All Keywords for Inclusion - Desc', type: 'sort' },
        { value: 'AllExcludedKeywords', text: 'All Keywords for Exclusion - Desc', type: 'sort' },
        { value: 'InclusionProbability', text: 'Sorted by Probability of Inclusion - Desc', type: 'sort' },
        { value: 'PublicationIdentity', text: 'Publication ID - Desc', type: 'sort' },
        { value: 'Author', text: 'Author - Desc', type: 'sort' },
        { value: 'Year', text: 'Year - Desc', type: 'sort' },
        { value: 'Title', text: 'Title - Desc', type: 'sort' },
        { value: 'Abstract', text: 'Abstract - Desc', type: 'sort' },
        { value: 'Journal', text: 'Journal - Desc', type: 'sort' },
        { value: 'TypeOfPublication', text: 'Type Of Publication - Desc', type: 'sort' },
        { value: 'CurrentStage', text: 'Current Stage - Desc', type: 'sort' },
        { value: 'MovedFrom', text: 'Moved From - Desc', type: 'sort' },
        { value: 'MovedBy', text: 'Moved By - Desc', type: 'sort' },
        { value: 'DateMoved', text: 'Date Moved - Desc', type: 'sort' },
        { value: 'AccessionNumber', text: 'Accession Number - Desc', type: 'othsorter' },
        { value: 'DOI', text: 'DOI - Desc', type: 'sort' },
        { value: 'StudyIdentity', text: 'Study ID - Desc', type: 'sort' },
        { value: 'StudyAcronym', text: 'Study Acronym - Desc', type: 'sort' },
        { value: 'StudyDescription', text: 'Study Description - Desc', type: 'sort' }
      ],
      filterActions: [
        { text: 'Clear All' },
        { text: 'Open' },
        { text: 'Save' }
      ]
    }
  }
}
</script>
