<template>
  <div style="width: 400px">
    <FormEntryContainer title="Filter Name" type="text" size="80"></FormEntryContainer>
    <FormEntryContainer title="Filter Description" type="textarea" size="400"></FormEntryContainer>
    <button style="width: 100px; height: 20px;">Save</button>
    <button style="width: 100px; height: 20px;">Cancel</button>
  </div>
</template>

<script>
import FormEntryContainer from '@/containers/FormEntryContainer'

export default {
  name: 'filter-open',
  components: {
    FormEntryContainer
  }
}
</script>
