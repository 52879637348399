<template>
    <div id="citation-list" class="citation-list">
      <div style='width: 100%; text-align: left; margin: 10px;'>
        <kendo-datasource
          :ref="'stageDataSource'"
          :data="filter2">
        </kendo-datasource>
        <kendo-dropdownlist
          :id="'stageListBox1'"
          :data-source="filter1"
          :data-value-field="'FilterIdentity'"
          :data-text-field="'FilterName'"
          :change="changeFilter1">
        </kendo-dropdownlist>
        Citations moved from:&nbsp;
        <kendo-dropdownlist
          :id="'stageListBox2'"
          :data-source-ref="'stageDataSource'"
          :data-value-field="'StageIdentity'"
          :data-text-field="'StageName'">
        </kendo-dropdownlist>
      </div>
      <kendo-datasource
          :ref="'validationPublicationDataSource'"
          :data="publicationDataSource.data"
          :filter="grdFilter">
      </kendo-datasource>
      <kendo-grid id="publications-list-grid"
        :ref="'citationListGrid'"
        style="citation-list-grid"
        :data-source-ref="'validationPublicationDataSource'"
        :columns="columns"
        :selectable="selectable"
        :sortable="sortable"
        :pageable="pageable"
        :groupable="groupable"
        :excel="excel"
        :scrollable="scrollable"
        :filterable="filterable"
        :change="change"
        :data-bound="dataBound">
      </kendo-grid>
    </div>
</template>

<script>

import PublicationDataSource from '@/assets/data/Citation.json'
import StageDataSource from '@/assets/data/Stage.json'
import mitt from 'mitt'
const emitter = mitt()

export default {
  name: 'citation-list',
  props: ['selected'],
  data () {
    return {
      gridWidget: '',
      excel: { fileName: 'Kendo UI Grid Export.xlsx', allPages: true },
      pageable: {
        refresh: true,
        pageSizes: true,
        buttonCount: 2,
        pageSize: 5
      },
      columns: [
        {
          title: 'Citation Fields',
          headerAttributes: { class: 'grid-header-light' },
          columns: [
            { field: 'PublicationIdentity', title: 'Citation ID', width: '50px', headerAttributes: { class: 'grid-header-gray' } },
            { field: 'Author', title: 'Author', width: '80px', template: '<div id="truncate">#:Author#</div>', headerAttributes: { class: 'grid-header-gray' } },
            { field: 'Year', title: 'Year', width: '60px', headerAttributes: { class: 'grid-header-gray' } },
            { field: 'Title', title: 'Title', width: '100px', template: '<div id="truncate">#:Title#</div>', headerAttributes: { class: 'grid-header-gray' } },
            { field: 'Tags', title: 'Tags', width: '60px', template: '<button>Tag1</button><button>Tag2</button>', headerAttributes: { class: 'grid-header-gray' } }
          ]
        },
        {
          title: 'Other Citations Fields',
          headerAttributes: { class: 'grid-header-light' },
          columns: [
            { field: 'CurrentStage', title: 'Current Stage', width: '75px', headerAttributes: { class: 'grid-header-light' } },
            { field: 'MovedFrom', title: 'Moved From', width: '75px', headerAttributes: { class: 'grid-header-light' } },
            { field: 'MovedFrom', title: 'Validated', width: '75px', headerAttributes: { class: 'grid-header-light' } }
          ]
        }
      ],
      groupable: false,
      selectable: 'multiple row',
      sortable: true,
      scrollable: true,
      filterable: false,
      ddFilter: {
        logic: 'or',
        filters: [
          { field: 'StageIdentity', operator: 'eq', value: 1 },
          { field: 'StageIdentity', operator: 'eq', value: 2 },
          { field: 'StageIdentity', operator: 'eq', value: 3 },
          { field: 'StageIdentity', operator: 'eq', value: 4 },
          { field: 'StageIdentity', operator: 'eq', value: 5 }
        ]
      },
      grdFilter: {
        logic: 'and',
        filters: [
          { field: 'ValidationPublication', operator: 'eq', value: true }
        ]
      },
      ddFilterAll: {
        logic: 'or',
        filters: [
          { field: 'StageIdentity', operator: 'eq', value: 1 },
          { field: 'StageIdentity', operator: 'eq', value: 2 },
          { field: 'StageIdentity', operator: 'eq', value: 3 },
          { field: 'StageIdentity', operator: 'eq', value: 4 },
          { field: 'StageIdentity', operator: 'eq', value: 5 }
        ]
      },
      grdFilterAll: {
        logic: 'and',
        filters: [
          { field: 'ValidationPublication', operator: 'eq', value: true }
        ]
      },
      ddFilterIncluded: {
        logic: 'or',
        filters: [
          { field: 'StageIdentity', operator: 'eq', value: 1 },
          { field: 'StageIdentity', operator: 'eq', value: 2 },
          { field: 'StageIdentity', operator: 'eq', value: 3 },
          { field: 'StageIdentity', operator: 'eq', value: 4 }
        ]
      },
      grdFilterIncluded: {
        logic: 'and',
        filters: [
          { field: 'ValidationPublication', operator: 'eq', value: true },
          { field: 'StageIdentity', operator: 'eq', value: 2 }
        ]
      },
      ddFilterExcluded: {
        logic: 'and',
        filters: [
          { field: 'StageIdentity', operator: 'eq', value: 5 }
        ]
      },
      grdFilterExcluded: {
        logic: 'and',
        filters: [
          { field: 'ValidationPublication', operator: 'eq', value: true },
          { field: 'StageIdentity', operator: 'eq', value: 5 }
        ]
      },
      publicationDataSource: PublicationDataSource,
      stageDataSource: StageDataSource,
      isMarked: false,
      filter1: [
        { FilterIdentity: 1, FilterName: 'All' },
        { FilterIdentity: 2, FilterName: 'Included' },
        { FilterIdentity: 3, FilterName: 'Excluded' }
      ],
      filter2: [
        { StageIdentity: 1, StageName: 'Abstract Screening' },
        { StageIdentity: 2, StageName: 'Full Text Screening' },
        { StageIdentity: 3, StageName: 'Study Mapping' },
        { StageIdentity: 4, StageName: 'Full Data Extraction' }
      ]
    }
  },
  methods: {
    change: function () {
      var selectedRow = this.$refs.citationListGrid.kendoWidget().select()
      emitter.emit('selectedValidationPublication', selectedRow)
    },
    changeFilter1: function (e) {
      switch (e.sender.selectedIndex) {
        case 0:
          this.ddFilter = this.ddFilterAll
          this.grdFilter = this.grdFilterAll
          break
        case 1:
          this.ddFilter = this.ddFilterIncluded
          this.grdFilter = this.grdFilterIncluded
          break
        case 2:
          this.ddFilter = this.ddFilterExcluded
          this.grdFilter = this.grdFilterExcluded
          break
      }
    },
    getColumnIndexFromField: function (field) {
      var index = -1
      var columns = this.gridWidget.options.columns
      if (columns.length > 0) {
        for (var i = 0; i < columns.length; i++) {
          if (columns[i].field === field) {
            index = i
          }
        }
      }
      if (index === -1) {
        console.log('column ' + field + ' not exists')
      } else {
        return index
      }
    },
    dataBound: function (e) {
      e.sender.select('tr:eq(0)')
    }
  }
}
</script>
