<template>
  <div>
    <div>Select a filter from the list</div>
    <kendo-datasource
      :ref="'filterDataSource'"
      :data="filterDataSource.data">
    </kendo-datasource>
    <kendo-grid
      :id="'filterGrid'"
      :ref="'filterGrid'"
      :data-source-ref="'filterDataSource'"
      :selectable="selectable"
      style="width: calc(100% - 1px);">
      <kendo-grid-column field="FilterName" title="Filter Name" width="40px"></kendo-grid-column>
      <kendo-grid-column field="FilterDescription" title="Filter Description" width="40px"></kendo-grid-column>
    </kendo-grid>
    <br>
    <button style="width: 150px; height: 20px;">Apply</button>
    <button style="width: 100px; height: 20px;">Cancel</button>
  </div>
</template>

<script>
import FilterDataSource from '@/assets/data/Filter.json'

export default {
  name: 'filter-open',
  data () {
    return {
      filterDataSource: FilterDataSource,
      selectable: true
    }
  }
}
</script>
