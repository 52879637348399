<template>
    <div class="action">
      <div class="first-action-validation-container">
        <div style="color: red;">With the current selection of filters you [Can/Cannot] [Include/Exclude] citations because...</div>
        <br>
        <div class="button-container-batch-review">
          <button class="button1-val" @click="toggleButton1" ref="button1">
              <h3 ref="button1Title"><u>I</u>nclude (Ctrl+I)</h3>
              <h4 ref="button2Label">Push to:</h4>
              <div v-on:click.stop>
                <kendo-dropdownlist
                  class="button-input-disc"
                  :ref = "'button1Input'"
                  :data-source="stageDataSource"
                  :data-text-field="'StageName'"
                  :data-value-field="'StageIdentity'"
                  :options-label="'Select stage...'">
                </kendo-dropdownlist>
              </div>
          </button>
          <button class="button2-val" @click="toggleButton2" ref="button2">
            <h3 ref="button2Title"><u>E</u>xclude (Ctrl+E)</h3>
            <h4 ref="button2Label">Reason(s):</h4>
            <div v-on:click.stop>
              <kendo-multiselect
                class="button2-input"
                :ref = "'button2Input'"
                :data-source="ExclusionReasonDataSource"
                :data-value-field="'ExclusionReasonIdentity'"
                :data-text-field="'ExclusionReasonName'"
                :placeholder="'Select reason...'">
                </kendo-multiselect>
            </div>
          </button>
        </div>
      </div>
      <div style="font-size:0.7em;padding-left:15px;">
        <u><a href="#">Rollback</a></u>
      </div>
      <div>
        <b-button-group class="action-buttons-nav">
          <b-button type="submit" variant="primary" size="sm" style="width: 90px" ref="applyButton" :disabled='applyIsDisabled'>Apply to selected</b-button>
        </b-button-group>
      </div>
    </div>
</template>

<script>
import StageDataSource from '@/assets/data/Stage.json'
import ExclusionReasonDataSource from '@/assets/data/ExclusionReason.json'
import AnnotationDataSource from '@/assets/data/Annotation.json'
import mitt from 'mitt'
const emitter = mitt()

export default {
  name: 'actions-stage-batch-review',
  methods: {
    toggleButton1: function () {
      this.activateAccept1 = !this.activateAccept1
      if (this.activateAccept1) {
        this.$refs.button1.className = 'button1-accept-val'
        this.$refs.button2.className = 'button2-val'
        this.button1InputWidget.value('1')
        this.button1InputWidget.enable(true)
        this.button2InputWidget.enable(false)
        this.button2InputWidget.value('')
        this.activateAccept2 = false
        this.applyIsDisabled = false
      } else {
        this.$refs.button1.className = 'button1-val'
        this.button1InputWidget.enable(false)
        this.button1InputWidget.value('')
        this.applyIsDisabled = true
      }
    },
    toggleButton2: function () {
      this.activateAccept2 = !this.activateAccept2
      if (this.activateAccept2) {
        this.$refs.button2.className = 'button2-accept-val'
        this.$refs.button1.className = 'button1-val'
        this.button2InputWidget.enable(true)
        this.button1InputWidget.enable(false)
        this.button1InputWidget.value('')
        this.applyIsDisabled = false
      } else {
        this.$refs.button2.className = 'button2-val'
        this.button2InputWidget.enable(false)
        this.button2InputWidget.value('')
        this.applyIsDisabled = true
      }
    }
  },
  data () {
    return {
      button1InputWidget: {},
      button2InputWidget: {},
      autoWidth: true,
      stageDataSource: StageDataSource,
      ExclusionReasonDataSource: ExclusionReasonDataSource,
      annotationDataSource: AnnotationDataSource,
      show1: 'Activate',
      show2: 'Show All Status',
      activateAccept1: false,
      activateAccept2: false,
      allStageFilter: true,
      allStatusFilter: true,
      applyIsDisabled: false,
      acceptOtherOptions: [
        { text: 'Abstract Screening' },
        { text: 'Full Text Screening' },
        { text: 'Added Documents' },
        { text: 'Full Data Extraction' },
        { text: 'Reject' }
      ]
    }
  },
  mounted: function () {
    this.button1InputWidget = this.$refs.button1Input.kendoWidget()
    this.button2InputWidget = this.$refs.button2Input.kendoWidget()
  },
  created: function () {
    emitter.on('selectedValidationPublication', (selectedRow) => {
      this.validationActionType = selectedRow[0].rowIndex
    })
  }
}
</script>
