var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"action",on:{"click":function($event){return _vm.setActionsFocus()}}},[_c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticStyle:{"width":"90%","display":"flex","flex-direction":"row","justify-content":"space-between"}},[_c('div',[_c('b-button',{staticStyle:{"width":"80px"},attrs:{"type":"submit","variant":"primary","size":"sm"}},[_vm._v("Previous")]),_c('b-button',{staticStyle:{"width":"80px"},attrs:{"type":"submit","variant":"primary","size":"sm"}},[_vm._v("Next")])],1),_c('b-button',{staticStyle:{"width":"80px"},attrs:{"type":"submit","variant":"primary","size":"sm"},on:{"click":function($event){return _vm.exit()}}},[_vm._v("Exit Extraction")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key-value-container"},[_c('div',{staticClass:"key"},[_vm._v("Current citation:")]),_c('div',{staticClass:"value"},[_vm._v("1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key-value-container"},[_c('div',{staticClass:"key"},[_vm._v("Author:")]),_c('div',{staticClass:"value"},[_vm._v("Zhang")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key-value-container"},[_c('div',{staticClass:"key"},[_vm._v("Year:")]),_c('div',{staticClass:"value"},[_vm._v("2016")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key-value-container"},[_c('div',{staticClass:"key"},[_vm._v("Title:")]),_c('div',{staticClass:"value"},[_vm._v("Pub title 1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key-value-container"},[_c('div',{staticClass:"key"},[_vm._v("Citation Type:")]),_c('div',{staticClass:"value"},[_vm._v("Main")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key-value-container"},[_c('div',{staticClass:"key"},[_vm._v("Study ID:")]),_c('div',{staticClass:"value"},[_vm._v("ER456")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key-value-container"},[_c('div',{staticClass:"key"},[_vm._v("Study Acronym:")]),_c('div',{staticClass:"value"},[_vm._v("Study Acronym")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"key-value-container"},[_c('div',{staticClass:"key"},[_vm._v("Study Description:")]),_c('div',{staticClass:"value"},[_vm._v("Study Description")])])
}]

export { render, staticRenderFns }