<template>
    <div class="action">
      <b-container class="actions-table-container" v-if="this.$store.state.isFirstActionVisualization">
        <b-row>
          <b-col sm="1"></b-col>
          <b-col sm="4">
            <label><b>From Current Stage:</b></label>
          </b-col>
          <b-col sm="6">
            <label>Abstract Screening</label>
          </b-col>
        </b-row>
        <b-row style="background: rgb(252, 237, 170); padding:5px;" align-v="center">
          <b-col sm="1">
            <input type="checkbox">
          </b-col>
          <b-col sm="4">
            <label style="color: black;"><b>Move to:</b></label>
          </b-col>
          <b-col sm="6">
            DE1
          </b-col>
        </b-row>
        <b-row style="background: rgb(214, 211, 241); padding:5px;" align-v="center">
          <b-col sm="1">
            <input type="checkbox">
          </b-col>
          <b-col sm="4">
            <label style="color: black;"><b>Move to:</b></label>
          </b-col>
          <b-col sm="6">
            DE2
          </b-col>
        </b-row>
        <b-row style="background: #bbbbbb; padding:5px;" align-v="center">
          <b-col sm="1">
            <input type="checkbox">
          </b-col>
          <b-col sm="4">
            <label style="color: black;"><b>Move to:</b></label>
          </b-col>
          <b-col sm="6">
            <kendo-dropdownlist
              :data-source="stageDataSource"
              :data-text-field="'StageName'"
              :data-value-field="'StageIdentity'"
              :options-label="'Select stage...'">
            </kendo-dropdownlist>
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col style="text-align: center;"><b>Or</b></b-col>
        </b-row>
        <b-row style="background: #db6664; padding:5px;" align-v="center">
          <b-col sm="4">
            <label style="color: black;"><b>Exclusion Reason:</b></label>
          </b-col>
          <b-col sm="8">
            <div>
              <kendo-multiselect
                :data-source="ExclusionReasonDataSource"
                :data-value-field="'ExclusionReasonIdentity'"
                :data-text-field="'ExclusionReasonName'"
                :placeholder="'Select reason...'">
              </kendo-multiselect>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div class="second-action-container" v-if="!this.$store.state.isFirstActionVisualization">
        <div class="label-container">
          <label><b>From current Stage:</b> Abstract Screening <b>To:</b></label>
        </div>
        <div class="button-container-disc">
          <button class="button1-disc" @click="toggleButton1" ref="button1">
              <h3 ref="button1Title">Accept Reviewer 1</h3>
              <h4 ref="button1Label">Push to:</h4>
              <div v-on:click.stop>
                <kendo-dropdownlist
                  class="button-input-disc"
                  :ref="'button1StageDiscrepancyInput'"
                  :data-source="stageDataSource"
                  :data-text-field="'StageName'"
                  :data-value-field="'StageIdentity'"
                  :options-label="'Select stage...'">
                </kendo-dropdownlist>
              </div>
          </button>
          <button class="button2-disc" @click="toggleButton2" ref="button2">
            <h3 ref="button2Title">Accept Reviewer 2</h3>
            <h4 ref="button1Label">Push to:</h4>
            <div v-on:click.stop>
              <kendo-dropdownlist
                class="button-input-disc"
                :ref="'button2StageDiscrepancyInput'"
                :data-source="stageDataSource"
                :data-text-field="'StageName'"
                :data-value-field="'StageIdentity'"
                :options-label="'Select stage...'"
                :auto-width="autoWidth">
              </kendo-dropdownlist>
            </div>
          </button>
          <button class="button3-disc" @click="toggleButton3" ref="button3">
            <h3 ref="button2Title">Other</h3>
            <div v-on:click.stop>
              <kendo-dropdownlist
                class="button-input-disc"
                :ref = "'button3Input'"
                :data-source="acceptOtherOptions"
                data-text-field="text"
                data-value-field="value"
                :auto-width="autoWidth">
              </kendo-dropdownlist>
            </div>
          </button>
        </div>
      </div>
      <div >
        <b-button-group class="action-buttons-nav">
          <b-button type="submit" variant="primary" size="sm" style="width: 80px">Accept and Next</b-button>
        </b-button-group>
      </div>
    </div>
</template>

<script>
import StageDataSource from '@/assets/data/Stage.json'
import ExclusionReasonDataSource from '@/assets/data/ExclusionReason.json'
import AnnotationDataSource from '@/assets/data/Annotation.json'

export default {
  name: 'actions-stage-discrepancies',
  methods: {
    itemTemplate: function () {
      return `<div class="k-widget action-annotations-item">
            <dl>
              <dd><b>#= kendo.toString(kendo.parseDate(Date), 'MM/dd/yyyy')# - #:StageName# - #:Section# - #:Author#</b>  #if (HighlightedText.length>0) {# "#:HighlightedText#" #}#  #:Comment#</dt>
            </dl>
          </div>`
    },
    editTemplate: function () {
      return `<div class="product-view k-widget">
            <dl>
              <dt>Annotation</dt>
              <dd>
                <input type="text" class="k-textbox" data-bind="value:Author" name="AnnotationTitle" required="required" validationMessage="required" />
                <span data-for="AnnotationTitle" class="k-invalid-msg"></span>
              </dd>
            </dl>
            <div>
              <a class="k-update-button" href="\\\\#">Save</span></a>
              <a class="k-cancel-button" href="\\\\#">Close</span></a>
            </div>
          </div>`
    },
    toggleButton1: function () {
      this.activateAccept1 = !this.activateAccept1
      if (this.activateAccept1) {
        this.$refs.buttonStageDiscrepancy1InputWidget.className = 'button1-accept-disc'
        this.$refs.buttonStageDiscrepancy2InputWidget.className = 'button2-disc'
        this.$refs.buttonStageDiscrepancy3InputWidget.className = 'button3-disc'
        this.buttonStageDiscrepancy1InputWidget.enable(true)
        this.buttonStageDiscrepancy2InputWidget.enable(false)
        this.buttonStageDiscrepancy2InputWidget.value('')
        this.buttonStageDiscrepancy3InputWidget.enable(false)
        this.buttonStageDiscrepancy3InputWidget.value('')
        this.buttonStageDiscrepancy3RejectWidget.enable(false)
        this.buttonStageDiscrepancy3RejectWidget.value('')
        this.activateAccept2 = false
        this.activateAccept3 = false
      } else {
        this.$refs.button1.className = 'button1-disc'
        this.button1InputWidget.enable(false)
        this.button1InputWidget.value('')
      }
    },
    toggleButton2: function () {
      this.activateAccept2 = !this.activateAccept2
      if (this.activateAccept2) {
        this.$refs.buttonStageDiscrepancy2InputWidget.className = 'button2-accept-disc'
        this.$refs.buttonStageDiscrepancy1InputWidget.className = 'button1-disc'
        this.$refs.buttonStageDiscrepancy3InputWidget.className = 'button3-disc'
        this.buttonStageDiscrepancy2InputWidget.enable(true)
        this.buttonStageDiscrepancy1InputWidget.enable(false)
        this.buttonStageDiscrepancy1InputWidget.value('')
        this.buttonStageDiscrepancy3InputWidget.enable(false)
        this.buttonStageDiscrepancy3InputWidget.value('')
        this.buttonStageDiscrepancy3RejectWidget.enable(false)
        this.buttonStageDiscrepancy3RejectWidget.value('')
        this.activateAccept1 = false
        this.activateAccept3 = false
      } else {
        this.$refs.button2.className = 'button2-disc'
        this.buttonStageDiscrepancy2InputWidget.enable(false)
        this.buttonStageDiscrepancy2InputWidget.value('')
      }
    },
    toggleButton3: function () {
      this.activateAccept3 = !this.activateAccept3
      if (this.activateAccept3) {
        this.$refs.buttonStageDiscrepancy3InputWidget.className = 'button3-accept-disc'
        this.$refs.buttonStageDiscrepancy1InputWidget.className = 'button1-disc'
        this.$refs.buttonStageDiscrepancy2InputWidget.className = 'button2-disc'
        this.button3InputWidget.enable(true)
        this.button3RejectWidget.enable(true)
        this.buttonStageDiscrepancy1InputWidget.enable(false)
        this.buttonStageDiscrepancy1InputWidget.value('')
        this.buttonStageDiscrepancy2InputWidget.enable(false)
        this.buttonStageDiscrepancy2InputWidget.value('')
        this.activateAccept1 = false
        this.activateAccept2 = false
      } else {
        this.$refs.buttonStageDiscrepancy3InputWidget.className = 'button3-disc'
        this.button3InputWidget.enable(false)
        this.button3InputWidget.value('')
        this.button3RejectWidget.enable(false)
        this.button3InputWidget.value('')
      }
    }
  },
  data () {
    return {
      button1InputWidget: {},
      button2InputWidget: {},
      button3InputWidget: {},
      button3RejectWidget: {},
      autoWidth: true,
      stageDataSource: StageDataSource,
      ExclusionReasonDataSource: ExclusionReasonDataSource,
      annotationDataSource: AnnotationDataSource,
      show1: 'Activate',
      show2: 'Show All Status',
      activateAccept1: false,
      activateAccept2: false,
      activateAccept3: false,
      allStageFilter: true,
      allStatusFilter: true,
      acceptOtherOptions: [
        { text: 'Abstract Screening' },
        { text: 'Full Text Screening' },
        { text: 'Added Documents' },
        { text: 'Full Data Extraction' },
        { text: 'Reject' }
      ],
      annotationOptions: [
        { text: 'Show All Annotations' },
        { text: 'Show Flagged Annotations' },
        { text: 'Show Citation Annotations' },
        { text: 'Show Abstract Annotations' },
        { text: 'Show Full Text Annotations' },
        { text: 'Show Citation Flagged Annotations' },
        { text: 'Show Abstract Flagged Annotations' },
        { text: 'Show Full Text Flagged Annotations' }
      ],
      annotationsListViewConfiguration: {
        itemTemplate: this.itemTemplate(),
        editTemplate: this.editTemplate()
      }
    }
  },
  mounted: function () {
    this.buttonStageDiscrepancy1InputWidget = this.$refs.buttonStageDiscrepancy1Input.kendoWidget()
    this.buttonStageDiscrepancy2InputWidget = this.$refs.buttonStageDiscrepancy2Input.kendoWidget()
    this.buttonStageDiscrepancy3InputWidget = this.$refs.buttonStageDiscrepancy3Input.kendoWidget()
    this.buttonStageDiscrepancy3RejectWidget = this.$refs.buttonStageDiscrepancy3Reject.kendoWidget()
  }
}
</script>
