<template>
  <div style="width: 400px">
    <FormEntryContainer title="Study ID" type="text" size="80px"></FormEntryContainer>
    <FormEntryContainer title="Acronym" type="text" size="150px"></FormEntryContainer>
    <FormEntryContainer title="Description" type="text" size="150px"></FormEntryContainer>
     <FormEntryContainer
      title="Study Type"
      type="dropdown"
      :datasource="this.studyTypeDataSource"
      :valuefield="'StudyTypeName'"
      :textfield="'StudyTypeName'">
    </FormEntryContainer>
    <div class="form-entry-container">
      <div class="form-entry-label">Arms</div>
      <div class="form-entry-input">
        <table style="width: 55em;"><tr><td style="width: 10%;">Arm</td><td style="width: 90%;">Name</td></tr></table>
        <kendo-listbox
          :id="'armListBox'"
          :data-source="armDataSource"
          :data-value-field="'ArmIdentity'"
          :data-text-field="'ArmName'"
          :template="armTemplate"
          :toolbar-tools="['moveUp', 'moveDown']"
          style="height:120px; padding-right: 15px; width: 45em;">
        </kendo-listbox>
        <div>
          <a href="#">Add</a>
          <a href="#">Edit</a>
          <a href="#">Delete</a>
        </div>
        <FormEntryContainer title="Number of Arms" type="text" size="5px" value="2"></FormEntryContainer>
      </div>
    </div>
    <div class="form-entry-container">
      <div class="form-entry-label">Subgroups</div>
      <div class="form-entry-input">
        <table style="width: 55em;"><tr><td style="width: 10%;">Subgroup</td><td style="width: 90%;">Name</td></tr></table>
        <kendo-listbox
          :id="'subgroupListBox'"
          :data-source="subgroupDataSource"
          :data-value-field="'FilterIdentity'"
          :data-text-field="'FilterName'"
          :toolbar-tools="['moveUp', 'moveDown']"
          :template="subgroupTemplate"
          style="height:120px; padding-right: 15px; width: 45em;">
        </kendo-listbox>
        <div>
          <a href="#">Add</a>
          <a href="#">Edit</a>
          <a href="#">Delete</a>
        </div>
        <FormEntryContainer title="Number of Subgroups" type="text" size="5px" value="3"></FormEntryContainer>
      </div>
    </div>
    <button style="width: 100px; height: 20px;">Save</button>
    <button style="width: 100px; height: 20px;">Cancel</button>
  </div>
</template>

<script>
import FormEntryContainer from '@/containers/FormEntryContainer'

export default {
  name: 'study-new',
  components: {
    FormEntryContainer
  },
  data () {
    return {
      studyTypeDataSource: [
        { StudyTypeName: 'Single Arm Trial (SAT)' },
        { StudyTypeName: 'Randomized Control Trial (RCT)' },
        { StudyTypeName: 'Non Randomized Control Trial (NRCT)' },
        { StudyTypeName: 'Control Clinical Trial (CCT)' },
        { StudyTypeName: 'Observational Study' },
        { StudyTypeName: 'Retrospective Study' },
        { StudyTypeName: 'Sistematic Literature Review (SLR)' },
        { StudyTypeName: 'Non Sistematic Literature Review (NSLR)' }
      ],
      armDataSource: [
        { ArmIdentity: 1, ArmName: 'Human IgG4 monoclonal antibody' },
        { ArmIdentity: 2, ArmName: 'nivolumab' },
        { ArmIdentity: 3, ArmName: 'nivolumab + ipilimumab' }
      ],
      armTemplate: '<table style="width: 100%;"><tr><td style="width: 10%;">#:ArmIdentity#</td><td style="width: 90%;">#:ArmName#</td></tr></table>',
      subgroupDataSource: [
        { SubgroupIdentity: 1, SubgroupName: 'Overall' },
        { SubgroupIdentity: 2, SubgroupName: 'Subgroup 1' },
        { SubgroupIdentity: 3, SubgroupName: 'Subgroup 2' }
      ],
      subgroupTemplate: '<table style="width: 100%;"><tr><td style="width: 10%;">#:SubgroupIdentity#</td><td style="width: 90%;">#:SubgroupName#</td></tr></table>'
    }
  }
}
</script>
