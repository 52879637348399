<template>
  <div class="action" @click="setActionsFocus()">
    <div class="annotations">
      <div>
          <kendo-datasource
            :ref="'annotationDataSource'"
            :page-size="4"
            :data="annotationDataSource.data">
          </kendo-datasource>
          <kendo-listview
            :ref="'annotationsListView'"
            :selectable="true"
            :data-source-ref="'annotationDataSource'"
            :template="annotationsListViewConfiguration.itemTemplate"
            :edit-template="annotationsListViewConfiguration.editTemplate"
            :pageable="true"
            :change="changeAnnotationSelection">
          </kendo-listview>
          <kendo-pager
            :data-source-ref="'annotationDataSource'">
          </kendo-pager>
          <div class="annotations-options">
            <div>
              <a href="\\\\#">New</a>
              <a class="k-update-button" href="\\\\#">Edit</a>
              <a href="\\\\#" @click="deleteAnnotation()">Delete</a>
              <a class="k-cancel-button" href="\\\\#">View</a>
              <font-awesome-icon :icon="'flag'" style="color: red;" fixed-width></font-awesome-icon>
            </div>
            <div style="display: flex; flex-direction: row; flex-gap: 5px;">
              <kendo-datasource
                :ref="'annotationFilterOptionDataSource'"
                :data="annotationFilterOptionDataSource.data">
              </kendo-datasource>
              <kendo-dropdownlist
                :ref="'annotationFilterOptionDropDownList'"
                :data-source-ref="'annotationFilterOptionDataSource'"
                :data-value-field="'AnnotationFilterOptionIdentity'"
                :data-text-field="'AnnotationFilterOptionName'">
              </kendo-dropdownlist>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

import StageDataSource from '@/assets/data/Stage.json'
import ExclusionReasonDataSource from '@/assets/data/ExclusionReason.json'
import AnnotationDataSource from '@/assets/data/Annotation.json'
import AnnotationFilterOptionDataSource from '@/assets/data/AnnotationFilterOption.json'
import mitt from 'mitt'
const emitter = mitt()

export default {
  name: 'annotations',
  props: ['type'],
  methods: {
    setActionsFocus: function () {
      this.$store.state.currentFocus = 'Actions'
    },
    addAnnotation: function (section, highlightedText, comment) {
      this.annotationDataSource.data.push(
        {
          AnnotationIdentity: (this.annotationDataSource.data.length + 1),
          Author: this.$store.state.logUser,
          Date: new Date(),
          StageName: this.$store.state.currentStage,
          Section: section,
          HighlightedText: highlightedText,
          Comment: comment
        }
      )
      this.$refs.annotationDataSource.kendoWidget().sort({ field: 'AnnotationIdentity', dir: 'desc' })
    },
    deleteAnnotation: function () {
      // console.log(this.$refs.annotationsListView.kendoWidget().element.children())
      // var index = this.$refs.annotationsListView.kendoWidget().select().index()
      // alert(index)
    },
    changeAnnotationSelection: function (e) {
      var selectedIndex = e.sender.select().index()
      var dataItem = this.$refs.annotationDataSource.kendoWidget().view()[selectedIndex]
      emitter.emit('sendHighlightedText', dataItem.Section, dataItem.HighlightedText)
    },
    itemTemplate: function () {
      return `<div class="k-widget action-annotations-item">
            <dl>
              <dd><b>#= kendo.toString(kendo.parseDate(Date), 'MM/dd/yyyy')# - #:StageName# - #:Section# - #:Author#</b>  #if (HighlightedText.length>0) {# "#:HighlightedText#" #}#  #:Comment#</dt>
            </dl>
          </div>`
    },
    editTemplate: function () {
      return `<div class="product-view k-widget">
            <dl>
              <dt>Annotation</dt>
              <dd>
                <input type="text" class="k-textbox" data-bind="value:Author" name="AnnotationTitle" required="required" validationMessage="required" />
                <span data-for="AnnotationTitle" class="k-invalid-msg"></span>
              </dd>
            </dl>
            <div>
              <a class="k-update-button" href="\\\\#">Save</span></a>
              <a class="k-cancel-button" href="\\\\#">Close</span></a>
            </div>
          </div>`
    },
    toggleButton1: function () {
      this.activateAccept1 = !this.activateAccept1
      if (this.activateAccept1) {
        this.$refs.button1.className = 'button1-include'
        this.$refs.button2.className = 'button2'
        this.$refs.button3.className = 'button3'
        this.button1InputWidget.enable(true)
        this.button2InputWidget.enable(false)
        this.button2InputWidget.value('')
        this.activateAccept2 = false
        this.activateAccept3 = false
      } else {
        this.$refs.button1.className = 'button1'
        this.button1InputWidget.enable(false)
        this.button1InputWidget.value('')
      }
    },
    toggleButton2: function () {
      this.activateAccept2 = !this.activateAccept2
      if (this.activateAccept2) {
        this.$refs.button2.className = 'button2-exclude'
        this.$refs.button1.className = 'button1'
        this.$refs.button3.className = 'button3'
        this.button2InputWidget.enable(true)
        this.button1InputWidget.enable(false)
        this.button1InputWidget.value('')
        this.activateAccept1 = false
        this.activateAccept3 = false
      } else {
        this.$refs.button2.className = 'button2'
        this.button2InputWidget.enable(false)
        this.button2InputWidget.value('')
      }
    },
    toggleButton3: function () {
      this.activateAccept3 = !this.activateAccept3
      if (this.activateAccept3) {
        this.$refs.button3.className = 'button3-accept'
        this.$refs.button1.className = 'button1'
        this.$refs.button2.className = 'button2'
        this.button1InputWidget.enable(false)
        this.button1InputWidget.value('')
        this.button2InputWidget.enable(false)
        this.button2InputWidget.value('')
        this.activateAccept1 = false
        this.activateAccept2 = false
      } else {
        this.$refs.button3.className = 'button3'
      }
    }
  },
  data () {
    return {
      button1InputWidget: {},
      button2InputWidget: {},
      button3InputWidget: {},
      activateInclude: false,
      activateExclude: false,
      stageDataSource: StageDataSource,
      ExclusionReasonDataSource: ExclusionReasonDataSource,
      annotationDataSource: AnnotationDataSource,
      annotationFilterOptionDataSource: AnnotationFilterOptionDataSource,
      annotationsListViewConfiguration: {
        itemTemplate: this.itemTemplate(),
        editTemplate: this.editTemplate()
      }
    }
  },
  mounted: function () {
    // Commented on 2022-01-03
    // emitter.on('addAnnotation', (section, highlightedText, comment) => {
    //   this.addAnnotation(section, highlightedText, comment)
    // })
    // this.button1InputWidget = this.$refs.button1Input.kendoWidget()
    // this.button2InputWidget = this.$refs.button2Input.kendoWidget()
    // if (!this.$store.state.isFirstActionVisualization) {
    //   this.button1InputWidget.enable(false)
    //   this.button2InputWidget.enable(false)
    // }
  },
  unmounted: function () {
    emitter.off('addAnnotation')
  }
}
</script>
