<template>
    <div id="citation-list" class="citation-list">
      <div style='width: 100%;' class='k-grid-toolbar' id="myToolbar">
        <a class="k-button k-button-icontext k-grid-review" id="myBatchSelectAll" href="#" data-role="button" aria-disabled="false" tabindex="0">Select all</a>
        <a class="k-button k-button-icontext k-grid-clear" id="myBatchClear" href="#" data-role="button" aria-disabled="false" tabindex="0" disabled>Clear all</a>
      </div>
      <kendo-grid id="citation-list-grid"
        :ref="'citationListGrid'"
        style="citation-list-grid"
        :data-source="publicationDataSource"
        :columns="columns"
        :selectable="selectable"
        :sortable="sortable"
        :pageable="pageable"
        :groupable="groupable"
        :excel="excel"
        :scrollable="scrollable"
        :filterable="filterable"
        :filter="filterGrid"
        :change="change"
        :data-bound="dataBound">
      </kendo-grid>
      <kendo-window :ref="'formEditWindow'"
                  :width="windowWidth"
                  :height="windowHeight"
                  :title="windowTitle"
                  :visible="windowVisible"
                  :modal="true"
                  :top="'0px'">
        <component :is="component"></component>
      </kendo-window>
    </div>
</template>

<script>

import PublicationDataSource from '@/assets/data/Citation.json'
import CitationViewComponent from '@/views/secure/manage/BatchReviewDetails.vue'
import SplitterContainer from '@/containers/SplitterContainer'
import $ from 'jquery'
import mitt from 'mitt'
const emitter = mitt()

export default {
  name: 'citation-list-batch-review',
  props: ['selected'],
  components: {
    SplitterContainer,
    CitationViewComponent
  },
  data () {
    return {
      gridWidget: '',
      excel: { fileName: 'Kendo UI Grid Export.xlsx', allPages: true },
      pageable: {
        refresh: true,
        pageSizes: true,
        buttonCount: 2,
        pageSize: 5
      },
      columns: [
        {
          title: 'Main Citation Fields',
          headerAttributes: { class: 'grid-header-light' },
          columns: [
            {
              title: 'Details',
              width: '70px',
              command: { name: ' ', iconClass: 'k-icon k-i-hyperlink-open-sm', click: this.viewForm },
              headerAttributes: { class: 'grid-header-light' }
            },
            {
              field: 'PublicationIdentity',
              title: 'Citation ID',
              width: '120px',
              headerAttributes: { class: 'grid-header-light' }
            },
            { field: 'Author', title: 'Author', width: '120px', template: '<div id="truncate">#:Author#</div>', headerAttributes: { class: 'grid-header-light' } },
            { field: 'Year', title: 'Year', width: '80px', headerAttributes: { class: 'grid-header-light' } },
            { field: 'Title', title: 'Title', width: '250px', template: '<div id="truncate">#:Title#</div>', headerAttributes: { class: 'grid-header-light' } },
            { field: 'TypeOfPublication', title: 'Type Of Publication', width: '120px', template: '<div id="truncate">#:TypeOfPublication#</div>', headerAttributes: { class: 'grid-header-light' } }
          ]
        },
        {
          title: 'Other Citation Fields',
          headerAttributes: { class: 'grid-header-light' },
          columns: [
            { field: 'CurrentStage', title: 'Current Stage', width: '200px', headerAttributes: { class: 'grid-header-light' } },
            { field: 'MovedFrom', title: 'Moved From', width: '200px', headerAttributes: { class: 'grid-header-light' } },
            { field: 'Tags', title: 'Tags', width: '200px', template: '<button>Tag1</button><button>Tag2</button>', headerAttributes: { class: 'grid-header-light' } },
            { field: 'ReviewStatus', title: 'Review Status', width: '200px', headerAttributes: { class: 'grid-header-light' } },
            { field: 'ReviewedBy', title: 'Reviewed By', width: '200px', headerAttributes: { class: 'grid-header-light' } },
            { field: 'ReviewedDate', title: 'Reviewed Date', width: '200px', headerAttributes: { class: 'grid-header-light' } },
            { field: 'AddedMaterials', title: 'Added Materials', width: '200px', headerAttributes: { class: 'grid-header-light' } },
            { field: 'FullText', title: 'Full Text Link', width: '200px', headerAttributes: { class: 'grid-header-light' }, template: '<div id="truncate">#:FullText#</div>' },
            { field: 'Abstract', title: 'Abstract', width: '400px', template: '<div id="truncate">#:Abstract#</div>', headerAttributes: { class: 'grid-header-light' } },
            { field: 'Volume', title: 'Volume', width: '200px', headerAttributes: { class: 'grid-header-light' } },
            { field: 'Issue', title: 'Issue', width: '200px', headerAttributes: { class: 'grid-header-light' } },
            { field: 'Pages', title: 'Page(s)', width: '200px', headerAttributes: { class: 'grid-header-light' } },
            { title: 'Conference Name', width: '200px', headerAttributes: { class: 'grid-header-light' } },
            { title: 'Date(s) of Conference', width: '200px', headerAttributes: { class: 'grid-header-light' } },
            { title: 'Report Name', width: '200px', headerAttributes: { class: 'grid-header-light' } },
            { title: 'Organizing Body', width: '200px', headerAttributes: { class: 'grid-header-light' } },
            { title: 'Date of Report', width: '200px', headerAttributes: { class: 'grid-header-light' } },
            { field: 'DatabaseName', title: 'Name of Database', width: '200px', headerAttributes: { class: 'grid-header-light' } },
            { field: 'AccessionNumber', title: 'Accession Number', width: '200px', headerAttributes: { class: 'grid-header-light' } },
            { field: 'DOI', title: 'DOI', width: '200px', template: '<div id="truncate">#:DOI#</div>', headerAttributes: { class: 'grid-header-light' } },
            { field: 'PrimaryPublication', title: 'Primary Publication', width: '200px', headerAttributes: { class: 'grid-header-light' } },
            { title: 'Has Flagged Annotations', width: '200px', headerAttributes: { class: 'grid-header-light' } }
          ]
        },
        {
          title: 'Study Fields',
          headerAttributes: { class: 'grid-header-dark' },
          columns: [
            { field: 'StudyIdentity', title: 'Registry #', width: '200px', headerAttributes: { class: 'grid-header-dark' } },
            { field: 'StudyAcronym', title: 'Study ID', width: '200px', headerAttributes: { class: 'grid-header-dark' } },
            { field: 'StudyDescription', title: 'Study Description', width: '200px', headerAttributes: { class: 'grid-header-dark' } }
          ]
        }
      ],
      groupable: false,
      selectable: 'multiple row',
      sortable: true,
      scrollable: true,
      filterable: {
        operators: {
          number: {
            eq: 'Equal to',
            neq: 'Not equal to',
            gt: 'Greater than',
            gte: 'Greater than or equal to',
            lt: 'Less than',
            lte: 'Less than or equal to'
          }
        }
      },
      component: 'CitationViewComponent',
      publicationDataSource: PublicationDataSource,
      windowVisible: false,
      isMarked: false
    }
  },
  created: function () {
    $('.k-grid-review').attr('disabled', true)
    $('.k-grid-clear').attr('disabled', true)
    emitter.on('removedCitationFilter', (columnName) => {
      var myFilters = this.gridWidget.dataSource.filter().filters
      myFilters.forEach(function (f) {
        if (f.field === columnName) {
          var index = myFilters.indexOf(f)
          myFilters.splice(index, 1)
        }
      })
      this.gridWidget.dataSource.filter([])
      this.gridWidget.dataSource.filter(myFilters)
    })
    emitter.on('removedStudyFilter', (columnName) => {
      var myFilters = this.gridWidget.dataSource.filter().filters
      myFilters.forEach(function (f) {
        if (f.field === columnName) {
          var index = myFilters.indexOf(f)
          myFilters.splice(index, 1)
        }
      })
      this.gridWidget.dataSource.filter([])
      this.gridWidget.dataSource.filter(myFilters)
    })
    emitter.on('removedOtherPublicationFilter', (columnName) => {
      var myFilters = this.gridWidget.dataSource.filter().filters
      myFilters.forEach(function (f) {
        if (f.field === columnName) {
          var index = myFilters.indexOf(f)
          myFilters.splice(index, 1)
        }
      })
      this.gridWidget.dataSource.filter([])
      this.gridWidget.dataSource.filter(myFilters)
    })
  },
  methods: {
    openReview: function () {
      $('.hdr-navigation-item').css('cursor', 'not-allowed')
      $('.hdr-title-text').css('cursor', 'not-allowed')
      $('.hdr-title').css('cursor', 'not-allowed')
      this.$store.state.selectedAction = 'manage-forms'
      this.$router.push({ path: '/secure/IndividualReview' })
    },
    disableReview: function (enable) {
      $('.k-grid-review').attr('disabled', enable)
    },
    disableClear: function (enable) {
      $('.k-grid-clear').attr('disabled', enable)
    },
    clearMarkedPublications: function () {
      // TODO
    },
    getColumnIndexFromField: function (field) {
      var index = -1
      var columns = this.gridWidget.options.columns
      if (columns.length > 0) {
        for (var i = 0; i < columns.length; i++) {
          if (columns[i].field === field) {
            index = i
          }
        }
      }
      if (index === -1) {
        console.log('column ' + field + ' not exists')
      } else {
        return index
      }
    },
    viewForm: function () {
      var windowWidget = this.$refs.formEditWindow.kendoWidget()
      this.windowTitle = 'Citation Details'
      this.windowWidth = '800px'
      this.windowHeight = '700px'
      this.windowVisible = true
      windowWidget.close()
      windowWidget.open().center()
    },
    filterGrid: function (e) {
      if (e.field === 'PublicationIdentity' | e.field === 'Author' | e.field === 'Year' | e.field === 'Title' | e.field === 'Abstract' | e.field === 'TypeOfPublication' | e.field === 'Volume' | e.field === 'Issue' | e.field === 'Pages' | e.field === 'ConferenceName' | e.field === 'ConferenceDates' | e.field === 'ReportName' | e.field === 'ReportDates' | e.field === 'OrganizingBody' | e.field === 'AccessionNumber' | e.field === 'DOI') {
        emitter.emit('addedCitationFilter', e.field, e.filter)
      }
      if (e.field === 'StudyIdentity' | e.field === 'StudyAcronym' | e.field === 'StudyDescription') {
        emitter.emit('addedStudyFilter', e.field, e.filter)
      }
      if (e.field === 'CurrentStage' | e.field === 'MovedFrom' | e.field === 'MovedBy' | e.field === 'DateMoved' | e.field === 'AddedMaterials' | e.field === 'FullText') {
        emitter.emit('addedOtherPublicationFilter', e.field, e.filter)
      }
    },
    change: function () {
      var selectedRows = this.$refs.citationListGrid.kendoWidget().select()
      this.$store.state.isStudyMapping = false
      for (var i = 0; i < selectedRows.length; i++) {
        var dataItem = this.$refs.citationListGrid.kendoWidget().dataItem(selectedRows[i])
        console.log(dataItem)
        if (dataItem.CurrentStage === 'Study Mapping') {
          this.$store.state.isStudyMapping = true
        }
      }
      this.disableReview(selectedRows.length === 0)
      this.disableClear(selectedRows.length === 0)
    },
    dataBound: function (e) {
      var selectedDataItems = this.$store.state.selectedDataItems
      if (selectedDataItems.length > 0) {
        var grid = e.sender
        var items = grid.items()
        items.each(function (idx, row) {
          var dataItem = grid.dataItem(row)
          if (selectedDataItems.indexOf(dataItem) !== -1) {
            e.sender.select('tr:eq(' + idx + ')')
          }
        })
      }
    }
  }
}
</script>

<style>
    #myToolbar {
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-template-rows: 20px 20px;
        justify-items: center;
    }
    #myExcel {
      grid-column: 1;
      grid-row: 1;
      justify-self: start;
    }

    #myReview {
      grid-column: 4;
      grid-row: 1;
      justify-self: end;
    }

    #myClear {
      grid-column: 4;
      grid-row: 2;
      justify-self: end;
    }

    #myBatchClear {
      grid-column: 6;
      grid-row: 2;
      justify-self: end;
    }

    #myBatchSelectAll {
      grid-column: 6;
      grid-row: 1;
      justify-self: end;
    }

    .k-icon:before {
      color: white !important;
    }

</style>
